'use client';
import React from 'react';
import { Section } from '../section/section.comp';
import { StyledDiv } from './valuePropositions.styles';
import Lottie from 'react-lottie-player';

import lottie1 from '../../../public/lotties/apps.json';
import lottie2 from '../../../public/lotties/customaizable.json';
import lottie3 from '../../../public/lotties/easy-to-use.json';
import lottie4 from '../../../public/lotties/mobile-editor.json';

export const ValuePropositions = () => {
    return (
        <Section>
            <StyledDiv>
                <div className="content-wrapper">
                    <h2>The Only Suite of Widgets You’ll Ever Need</h2>
                    <p>
                        Common Ninja’s ever-growing suite of professional and reliable widgets will help you save time and money and improve your website beyond what your current site-building platform offers.
                    </p>
                    <p>
                        Manage all your widgets, for as many projects as you may have, from a single dashboard. Collaborate with others, and use the most advanced no-code widget editor out there.
                    </p>
                    <div className="benefits-container">
                        <div className="seperator">
                            <div className="benefit-card">
                                <Lottie
                                    animationData={lottie1}
                                    loop
                                    play
                                    className="lottie"
                                    aria-label="Lottie animation"
                                />
                                <h3>100+ Powerful Widgets</h3>
                                <p>Our collection of widgets will answer all of your website-building needs by completing the functionality and features that your current site-building platform lacks. </p>
                            </div>
                            <div className="benefit-card">
                                <Lottie
                                    animationData={lottie2}
                                    loop
                                    play
                                    className="lottie"
                                    aria-label="Lottie animation"
                                />
                                <h3>Fully Customizable Widgets</h3>
                                <p>Our widgets offer several levels of customization, allowing you to match the widgets to your website’s brand and style.</p>
                            </div>
                        </div>
                        <div className="seperator">
                            <div className="benefit-card">
                                <Lottie
                                    animationData={lottie3}
                                    loop
                                    play
                                    className="lottie"
                                    aria-label="Lottie animation"
                                />
                                <h3>Easy-To-Use Editor</h3>
                                <p>Our widget editor is easy to use, requires no coding skills or knowledge, and will help you to get the results you want with a few simple clicks.</p>
                            </div>
                            <div className="benefit-card">
                                <Lottie
                                    animationData={lottie4}
                                    loop
                                    play
                                    className="lottie"
                                    aria-label="Lottie animation"
                                />
                                <h3>On-the-Go Widget Customization</h3>
                                <p>Our widget editor is optimized for mobile devices, empowering you to edit widgets seamlessly while maintaining your productivity on the move.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledDiv>
        </Section>
    );
};

