import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	max-width: 1420px;
	margin: 0 auto;
	position: relative;
	background-color: ${CSSVars.gray700};
	border-radius: 10px;
	color: ${CSSVars.white};
	text-align: center;
	overflow: hidden;
	padding: 50px 20px;
	border: 1px solid #eeeeee;

	.rich-text {
		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}

	@media screen and (min-width: ${CSSVars.breakpointMD}) {
		padding: 100px 30px;
	}

	h3 {
		font-weight: 600;
		font-size: ${CSSVars.fontSize2XL};
		max-width: 700px;
		margin: 0 auto;
		z-index: 10;
		position: relative;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	p {
		font-weight: 300;
		font-size: ${CSSVars.fontSizeMD};
		max-width: 800px;
		z-index: 10;
		position: relative;
		margin: 20px auto 30px;

		&.small {
			margin: 10px auto 15px;
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			margin: 30px auto 50px;
			font-size: ${CSSVars.fontSizeLG};
			&.small {
				margin: 15px auto 25px;
			}
		}
	}

	a {
		margin: 30px auto 0;
		z-index: 10;
		position: relative;
		cursor: pointer;
		font-weight: 600;
		font-size: ${CSSVars.fontSizeMD};
		padding: 12px 25px;

		&.small {
			margin: 15px auto 0;
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			padding: 16px 30px;
			font-size: ${CSSVars.fontSizeLG};
			margin: 50px auto 0;
			&.small {
				margin: 25px auto 0;
			}
		}
	}

	.icons-left {
		position: absolute;
		z-index: 1;
		transform: scale(0.5);
		left: -230px;
		bottom: -220px;
		opacity: 0;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			transform: scale(0.8);
			opacity: 1;
			left: -270px;
			bottom: 10px;
		}

		@media screen and (min-width: ${CSSVars.breakpointXL}) {
			transform: scale(1);
			left: -25px;
			bottom: -25px;
		}
	}

	.icons-right {
		position: absolute;
		right: -250px;
		top: 220px;
		z-index: 1;
		transform: scale(0.5);
		opacity: 0;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			transform: scale(0.8);
			opacity: 1;
			right: -230px;
			top: 0px;
		}

		@media screen and (min-width: ${CSSVars.breakpointXL}) {
			transform: scale(1);
			right: -5px;
			top: -15px;
		}
	}
`;
