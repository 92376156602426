import { Banner } from "../components/banner/banner.comp";
import { Features } from "../components/features/features.comp";
import { ForAgencies } from "../components/forAgencies/forAgencies.comp";
import { Hero } from "../components/hero/hero.comp";
import { Layout } from "../components/layout/layout.comp";
import { Numbers } from "../components/numbers/numbers.comp";
import { Steps } from "../components/steps/steps.comp";
import { SupportedPlatforms } from "../components/supportedPlatforms/supportedPlatforms.comp";
import { Testimonials } from "../components/testimonials/testimonials.comp";
import { TrustedBy } from "../components/trustedBy/trustedBy.comp";
import { ValuePropositions } from "../components/valuePropositions/valuePropositions.comp";
import { WhyUse } from "../components/whyUse/whyUse.comp";
import { getDataFromDB } from "../lib/mongodb";
import { IPlugin } from "../types";

export default function Home({ apps }: { apps: IPlugin[]; }) {
  return (
    <Layout
      showFooter={true}
      showHeader={true}
      apps={apps}
      image="https://website-assets.commoninja.com/distribution/1672309693637_og_general_blue.png"
    >
      <Hero />
      <TrustedBy />
      <ValuePropositions />
      <Features />
      <ForAgencies />
      <Steps />
      <Numbers />
      <WhyUse />
      <Testimonials mode="light" />
      <SupportedPlatforms />
      <Banner
        showIcons={true}
        title={`Upgrade Your Website With ${apps.length} Powerful Widgets!`}
        text="Browse through Common Ninja’s comprehensive list of powerful widgets, discover how they can help you to enrich your website, and try them for free."
        buttonText="See our full collection"
        buttonLink="/widgets"
      />
    </Layout>
  );
}

export async function getStaticProps() {
  const apps = await getDataFromDB<IPlugin>("apps", {
    status: "published"
  });

  return {
    props: { apps },
  };
}
