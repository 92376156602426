'use client';
import React from 'react';
import { Feature } from '../feature/feature.comp';
import { Section } from '../section/section.comp';
import { StyledDiv } from './features.styles';
import { CSSVars } from '../../styles/styles.config';
import { MdModeEdit } from '@react-icons/all-files/md/MdModeEdit';
import { FaPlug } from '@react-icons/all-files/fa/FaPlug';
import { IoExtensionPuzzle } from '@react-icons/all-files/io5/IoExtensionPuzzle';
import { IoColorWand } from '@react-icons/all-files/io5/IoColorWand';
import { IoLanguage } from '@react-icons/all-files/io5/IoLanguage';
import { MdDevices } from '@react-icons/all-files/md/MdDevices';
import { RiLockFill } from '@react-icons/all-files/ri/RiLockFill';
import { FaImages } from '@react-icons/all-files/fa/FaImages';
import { MdTimer } from '@react-icons/all-files/md/MdTimer';
import { FaBell } from '@react-icons/all-files/fa/FaBell';
import { BiSupport } from '@react-icons/all-files/bi/BiSupport';
import { AiFillSafetyCertificate } from '@react-icons/all-files/ai/AiFillSafetyCertificate';

export const Features = () => {
	return (
		<Section>
			<StyledDiv>
				<div className="features-container">
					<Feature
						icon={<MdModeEdit size={26} color={`${CSSVars.primary500}`} />}
						title="Advanced No-Code Editor"
						text="Our editor empowers users with its intuitive interface, catering to both professionals and beginners."
						checkedItems={[
							'Full control over content and styles',
							'Powerful no-code css for advanced users',
							'Easy to navigate editor for beginners',
							'Intuitive interface for seamless editing experience'
						]}
						position="rtl"
						alt="Advanced editor illustrarion"
						img="/assets/editor-featue.png"
					/>
					<Feature
						icon={<FaPlug size={26} color={`${CSSVars.primary500}`} />}
						title="Seamless Data Integrations"
						text="Elevate your widgets with seamless connections to diverse data sources, enabling dynamic content and real-time synchronization. Transform your widgets into intelligent tools with ease."
						checkedItems={[
							'Connect effortlessly to CSV, APIs, Airtable, and Notion',
							'Gain valuable user behavior and conversion data through integrated analytics tools',
							'Ensure relevance with automatic, real-time data synchronization',
							'Tailor data and analytics sources to match your specific needs for optimal performance'
						]}
						position="ltr"
						alt="Data integrations illustrarion"
						img="/assets/data-integrations-feature.png"
					/>
					<Feature
						icon={<IoExtensionPuzzle size={26} color={`${CSSVars.primary500}`} />}
						title="Easy Management"
						text="Our specialized dashboard simplifies managing multiple websites and clients, enabling seamless collaboration with customers, designers, and marketing professionals."
						checkedItems={[
							'Easy project management for efficient workflow',
							'Collaborate and share projects effortlessly',
							'Manage project media for seamless integration and usage'
						]}
						position="rtl"
						alt="Project managment illustrarion"
						img="/assets/projects-feature.png"
					/>
					<Feature
						icon={<IoColorWand size={26} color={`${CSSVars.primary500}`} />}
						title="AI-Powered Widget Creation"
						text="Immerse yourself in widget creation like never before. Our AI technology elevates your content and equips widgets with intelligent, intuitive features, ensuring they stay ahead of the curve."
						checkedItems={[
							'Create compelling content',
							'Enhance your writing',
							'Tailor content precisely to your requirements',
							'Adapt tone of voice seamlessly'
						]}
						position="ltr"
						alt="AI-Powered widget creation illustrarion"
						img="/assets/ai-feature.png"
					/>
				</div>
				<div className='more-features-wrapper'>
					<h2>More Features</h2>
					<div className='more-features'>
						<div className="feature-item">
							<div className="icon-wrapper">
								<IoLanguage size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Localization</p>
								<p className="description">Support multiple languages in a single widget</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<MdDevices size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Responsiveness</p>
								<p className="description">Seamlessly adapts to all sizes for an optimal user experience</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<RiLockFill size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Privacy & GDPR Compliance</p>
								<p className="description">Ensures user data protection and compliance with privacy regulations</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<FaImages size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Advanced Media Manager</p>
								<p className="description">Easily manage your project’s media to use in all your widgets</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<MdTimer size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Fast Loading</p>
								<p className="description">Minimal code ensures swift loading times, enhancing user satisfaction</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<AiFillSafetyCertificate size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Secure</p>
								<p className="description">Built with security measures to safeguard your website and users</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<FaBell size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Notifications</p>
								<p className="description">Receive email or API notifications, keeping you informed about user interactions</p>
							</div>
						</div>
						<div className="feature-item">
							<div className="icon-wrapper">
								<BiSupport size={26} color={`${CSSVars.primary500}`} />
							</div>
							<div className="text-wrapper">
								<p className="title">Exceptional Support</p>
								<p className="description">Dedicated 5-star rated support team to assist you promptly and effectively</p>
							</div>
						</div>

					</div>
				</div>
			</StyledDiv>
		</Section>
	);
};
