import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	.agency-card {
		max-width: 1420px;
		margin: 0 auto;
		background: linear-gradient(255.96deg, #7d93ed 0%, #3047a6 100%);
		border-radius: 10px;
		color: ${CSSVars.white};
		overflow: hidden;
		position: relative;
		padding: 30px 30px 30px 0;
		
		@media screen and (min-width: ${CSSVars.breakpointXL}) {
			display: flex;
			justify-content: space-between;
			padding: 0;
		}

		img {
			margin-left: auto;
			display: none;
			max-height: 100%;
			object-fit: cover;

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				display: flex;
			}
		}

		@media screen and (min-width: ${CSSVars.breakpointXL}) {
			gap: 20px;
			padding: 0;
			flex-direction: row;
		}

		.content {
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 0 0 0 30px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				padding: 30px;
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				padding: 0 0 0 100px;
			}

			h3 {
				font-weight: 600;
				font-size: ${CSSVars.fontSize2XL};
				margin-bottom: 20px;

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					font-size: ${CSSVars.fontSize3XL};
					margin-bottom: 30px;
				}
			}

			p {
				font-weight: 300;
				font-size: ${CSSVars.fontSizeMD};

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					font-size: ${CSSVars.fontSizeLG};
				}
			}
		}
	}
`;
