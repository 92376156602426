import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledHero = styled.section`
	overflow: hidden;
	padding: 50px 20px 50px;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 160px 40px 100px;
	}

	.hero-content-container {
		max-width: 1420px;
		margin: 0 auto;
		color: ${CSSVars.gray900};
		text-align: center;
		position: relative;
		/* overflow: hidden; */

		.right-icons-wrapper {
			position: absolute;
			left: 58%;
			top: -50px;
			transform: scale(0.3);
			opacity: 0;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				opacity: 1;
				left: 85%;
			}

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				transform: scale(0.5);
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				transform: scale(0.7);
			}

			@media screen and (min-width: ${CSSVars.breakpoint2XL}) {
				transform: scale(1);
				left: 90%;
			}
		}

		.icons {
			position: relative;

			.audio-player-icon {
				position: absolute;
				transition: 0.2s;
				top: 185px;
				left: 0;
			}
		}

		.icons-img.left {
			position: absolute;
			display: none;
			opacity: 0;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				opacity: 1;
				display: flex;
				transform: scale(0.3);
				right: 70%;
				top: -100px;
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				transform: scale(0.5);
				right: 87%;
				top: -100px;
			}

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				transform: scale(0.7);
			}

			@media screen and (min-width: ${CSSVars.breakpoint2XL}) {
				transform: scale(1);
			}
		}

		h1 {
			font-weight: 600;
			margin: 0 auto;
			position: relative;
			z-index: 10;

			br {
				display: none;
			}

			span {
				color: ${CSSVars.primary500};
			}

			font-size: ${CSSVars.fontSize2XL};
			text-align: left;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				text-align: center;
				font-size: ${CSSVars.fontSize3XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize4XL};
				max-width: 850px;

				br {
					display: initial;
				}
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				font-size: ${CSSVars.fontSize6XL};
				max-width: 1150px;
			}
		}

		h2 {
			font-weight: 300;
			position: relative;
			z-index: 10;
			font-size: ${CSSVars.fontSizeLG};
			text-align: left;
			margin: 20px auto 40px;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				text-align: center;
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				max-width: 850px;
				font-size: ${CSSVars.fontSizeXL};
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				margin: 30px auto 50px;
				max-width: 820px;
			}
		}

		a {
			position: relative;
			z-index: 10;
			padding: 16px 30px;
			font-weight: 600;
			font-size: ${CSSVars.fontSizeMD};
			margin: 0 0 30px;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				margin: 0 auto 30px;
			}
			
			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSizeXL};
			}
		}
	}
`;
