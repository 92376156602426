'use client';
import React from 'react';
import { StyledDiv } from './numbers.styles';
import { Section } from '../section/section.comp';

export const Numbers = () => {
    return (
        <Section>
            <StyledDiv>
                <h2>The Numbers Say It All</h2>
                <div className="cards-container">
                    <div className="card">
                        <h3>1M+</h3>
                        <h4>Widgets</h4>
                        <p>have been created with Common Ninja.</p>
                    </div>
                    <div className="card">
                        <h3>50M+</h3>
                        <h4>Users</h4>
                        <p>interact with our widgets every month.</p>
                    </div>
                    <div className="card">
                        <h3>500K+</h3>
                        <h4>Businesses</h4>
                        <p>use our widgets on their websites & online stores.</p>
                    </div>
                </div>
            </StyledDiv>
        </Section>
    );
};

