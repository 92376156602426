import React from 'react';
import { Section } from '../section/section.comp';
import { StyledDiv } from './steps.styles';
import { CSSVars } from '../../styles/styles.config';
import { MdWidgets } from '@react-icons/all-files/md/MdWidgets';
import { FaPalette } from '@react-icons/all-files/fa/FaPalette';
import { FaCode } from '@react-icons/all-files/fa/FaCode';
import { IoIosCheckmarkCircle } from '@react-icons/all-files/io/IoIosCheckmarkCircle';

export const Steps = () => {
    return (
        <Section>
            <StyledDiv>
                <div className="content-wrapper">
                    <h2>Enhance your website in 4 simple steps</h2>
                    <p>
                        Discover the transformative power of our platform with these easy steps to enhance your website and elevate your online presence.
                    </p>
                    <div className="steps-container">
                        <div className="step">
                            <div className="icon-wrapper">
                                <MdWidgets size={26} color={CSSVars.primary500} />
                            </div>
                            <div className="details">
                                <p className="number">01</p>
                                <h3>Create Your Widget</h3>
                                <p>Start by designing your widget from scratch or choose from our extensive library of pre-made options.</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="icon-wrapper">
                                <FaPalette size={26} color={CSSVars.primary500} />
                            </div>
                            <div className="details">
                                <p className="number">02</p>
                                <h3>Customize to Fit Your Needs</h3>
                                <p>Personalize your widget effortlessly with intuitive customization tools. Tailor it to match your brand and requirements perfectly.</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="icon-wrapper">
                                <FaCode size={26} color={CSSVars.primary500} />
                            </div>
                            <div className="details">
                                <p className="number">03</p>
                                <h3>Embed on Your Website</h3>
                                <p>Effortlessly embed your customized widget, and instantly enhance functionality and appeal.</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="icon-wrapper">
                                <IoIosCheckmarkCircle size={26} color={CSSVars.primary500} />
                            </div>
                            <div className="details">
                                <p className="number">04</p>
                                <h3>Success Unveiled</h3>
                                <p>Witness your customized widget seamlessly integrated and showcased on your website, bringing your vision to life.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledDiv>
        </Section>
    );
};

