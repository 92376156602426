'use client';
import Image from 'next/image';
import React from 'react';
import { StyledDiv } from './forAgencies.styles';
import agencyImage from '../../../public/assets/agency.png';
import { Section } from '../section/section.comp';

export const ForAgencies = () => {
    return (
        <Section>
            <StyledDiv>
                <div className="agency-card">
                    <div className="content">
                        <h3>Ideal for Agencies</h3>
                        <p>Our goal is to create the ultimate widget platform that will empower agencies and enable them to build websites faster, and more efficiently, and save time and money in the process.

                            We constantly improve our platform, build more widgets, and add more features and capabilities to it.

                            We welcome open communication with our customers to understand how we can improve our products to meet your needs.
                        </p>
                    </div>
                    <Image src={'/assets/agency.png'} alt='People in a meeting' width={688} height={500} />
                </div>
            </StyledDiv>
        </Section>
    );
};

