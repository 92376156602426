'use client';
import React, { useRef } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { CustomLink } from "../link/link.comp";
import { StyledHero } from "./hero.styles";
import { SocialProof } from "../socialProof/socialProof.comp";

const Dragon = dynamic(() =>
  import("../dragon/dragon.comp"),
  {
    ssr: false
  }
);

export const Hero = () => {
  const ref: any = useRef<HTMLImageElement>();

  return (
    <StyledHero>
      <div className="hero-content-container">
        <h1>
          The <span>Ultimate</span> Suite of Widgets <br />
          for Any Website & Online Store
        </h1>
        <h2>
          Boost your website with an extensive collection of professional
          widgets. Try for free. No coding skills are required.{" "}
        </h2>
        <CustomLink href="/widgets" title="Explore Our Widgets">
          Explore Our Widgets
        </CustomLink>
        <SocialProof  />
        <div className="right-icons-wrapper">
          <div className="icons">
            <Image
              className="icons-img right"
              src="/assets/hero-icons-right.svg"
              alt="icons"
              width={403}
              height={449}
            />
            <Image
              ref={ref}
              className="audio-player-icon"
              src="/assets/audio-player.svg"
              alt="audio player icon"
              width={80}
              height={80}
            />
          </div>
        </div>
        <Image
          className="icons-img left"
          src="/assets/hero-icons-left.svg"
          alt="icons"
          width={423}
          height={489}
        />
      </div>

      <Dragon iconRef={ref} />
    </StyledHero>
  );
};

const users = [
  {
    name: "Matthew Brown",
    src: "https://website-assets.commoninja.com/distribution/1672237892897_header-person-1.png",
  },
  {
    name: "Sam Taylor",
    src: "https://website-assets.commoninja.com/distribution/1672237905508_header-person-2.png",
  },
  {
    name: "Madison Reed",
    src: "https://website-assets.commoninja.com/distribution/1672237913749_header-person-3.png",
  },
  {
    name: "Daniel Levy",
    src: "https://website-assets.commoninja.com/distribution/1672237920681_header-person-4.png",
  },
];
