import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	.features-container {
		max-width: 1420px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: 100px;
	}

	.more-features-wrapper {
		margin-top: 100px;
		
		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			margin-top: 200px;
		}

		h2 {
			font-weight: 600;
			font-size: ${CSSVars.fontSize2XL};
			margin-bottom: 30px;
			text-align: center;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize4XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				font-size: ${CSSVars.fontSize4XL};
			}
		}

		.more-features {
			margin: 0 auto;
			display: grid;
			max-width: 1030px;
			grid-template-columns: repeat(1, minmax(0, 1fr));
			gap: 50px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			.feature-item {
				display: flex;
				gap: 20px;
				align-items: flex-start;

				.icon-wrapper {
					width: fit-content;
					background-color: ${CSSVars.gray100};
					border: 1px solid ${CSSVars.gray200};
					padding: 10px;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 20px;
				}

				.text-wrapper {
					display: flex;
					flex-direction: column;
					gap: 5px;

					.title {
						font-weight: 600;
						font-size: ${CSSVars.fontSizeLG};

						@media screen and (min-width: ${CSSVars.breakpointLG}) {
							font-size: ${CSSVars.fontSizeXL};
						}
					}

					.description {
						font-weight: 300;
						font-size: ${CSSVars.fontSizeMD};
						color: ${CSSVars.gray900};

						@media screen and (min-width: ${CSSVars.breakpointLG}) {
							font-size: ${CSSVars.fontSizeLG};
						}
					}
				}
			}
		}
	}
`;
