import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	.content-wrapper {
		max-width: 1420px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		color: ${CSSVars.gray900};
		margin: 0 auto;

		h2 {
			font-weight: 600;
			font-size: ${CSSVars.fontSize2XL};
			margin-bottom: 30px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize4XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				font-size: ${CSSVars.fontSize4XL};
			}
		}

		p {
			font-weight: 300;
			max-width: 600px;
			font-size: ${CSSVars.fontSizeMD};
			text-align: left;

			&:first-of-type {
				margin-bottom: 15px;
			}

			&:last-of-type {
				margin-bottom: 80px;
			}

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				max-width: 930px;
				text-align: center;
				font-size: ${CSSVars.fontSizeLG};
			}
		}

		.steps-container {
			display: grid;
			grid-template-columns: repeat(1, minmax(0, 1fr));
			width: 100%;
			gap: 30px;

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				gap: 50px;
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}

			.step {
				position: relative;
				width: fit-content;
				align-items: flex-start;
				display: flex;
				margin: 0 auto;
				gap: 20px;
				flex-direction: row;

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					flex-direction: column;
				}

				&:not(&:last-of-type) {
					&::before {
						content: '';
						width: 1px;
						height: calc(100% - 70px);
						position: absolute;
						border-left: 1px solid ${CSSVars.gray300};
						left: 25px;
						top: 70px;

						@media screen and (min-width: ${CSSVars.breakpointLG}) {
							height: 1px;
							width: calc(100% - 70px);
							border-top: 1px solid ${CSSVars.gray300};
							top: 25px;
							left: 70px;
						}
					}
				}

				.icon-wrapper {
					width: fit-content;
					background-color: ${CSSVars.gray100};
					border: 1px solid ${CSSVars.gray200};
					padding: 10px;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.details {
					.number {
						font-weight: 600;
						font-size: ${CSSVars.fontSizeMD};
						color: ${CSSVars.gray400};
						margin: 0;

						@media screen and (min-width: ${CSSVars.breakpointMD}) {
							font-size: ${CSSVars.fontSizeLG};
						}
					}

					h3 {
						text-align: left;
						color: ${CSSVars.black};
						font-weight: 600;
						font-size: ${CSSVars.fontSizeLG};
						margin: 5px 0 10px;

						@media screen and (min-width: ${CSSVars.breakpointMD}) {
							font-size: ${CSSVars.fontSizeXL};
						}
					}

					p {
						text-align: left;
						font-weight: 300;
						font-size: ${CSSVars.fontSizeMD};
						color: ${CSSVars.gray900};
						margin: 0;
						max-width: 650px;

						@media screen and (min-width: ${CSSVars.breakpointMD}) {
							font-size: ${CSSVars.fontSizeLG};
						}
					}
				}
			}
		}
	}
`;
