import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	.content-wrapper {
		max-width: 1420px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		color: ${CSSVars.gray900};
		margin: 0 auto;

		h2 {
			font-weight: 600;
			font-size: ${CSSVars.fontSize2XL};
			margin-bottom: 30px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize4XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				font-size: ${CSSVars.fontSize4XL};
			}
		}

		p {
			font-weight: 300;
			max-width: 600px;
			font-size: ${CSSVars.fontSizeMD};
			text-align: left;

			&:first-of-type {
				margin-bottom: 15px;
			}

			&:last-of-type {
				margin-bottom: 80px;
			}

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				max-width: 930px;
				text-align: center;
				font-size: ${CSSVars.fontSizeLG};
			}
		}

		.benefits-container {
			display: flex;
			/* align-items: center; */
			justify-content: center;
			flex-wrap: wrap;
			gap: 35px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				gap: 50px;
			}

			.seperator {
				display: flex;
				gap: 35px;
				flex-wrap: wrap;
				flex: 250px;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					gap: 50px;
				}
				
				.benefit-card {
					display: flex;
					flex-direction: column;
					text-align: left;
					flex: 250px;

					.lottie {
						width: 60px;
						height: 60px;
						/* background-color: ${CSSVars.primary500}; */
						border-radius: 5px;
					}

					h3 {
						font-size: ${CSSVars.fontSizeXL};
						font-weight: 600;
						margin: 20px 0 10px;

						@media screen and (min-width: ${CSSVars.breakpointMD}) {
							margin: 30px 0 20px;
						}
					}

					p {
						font-size: ${CSSVars.fontSizeMD};
						font-weight: 300;
						text-align: left;
						margin: 0;

						@media screen and (min-width: ${CSSVars.breakpointSM}) {
							font-size: ${CSSVars.fontSizeLG};
						}
					}
				}
			}
		}
	}
`;
