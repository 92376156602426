import React from 'react';
import { StyledDiv } from './banner.styles';
import { Section } from '../section/section.comp';
import Image from 'next/image';
import { CSSProperties } from 'styled-components';
import { CustomLink } from '../link/link.comp';

interface IBannerProps {
	showIcons?: boolean;
	title?: string;
	text: string;
	buttonText?: string;
	buttonLink?: string;
	openInNewWindow?: boolean;
	style?: CSSProperties;
	isHtml?: boolean;
	bannerStyle?: CSSProperties;
	size?: 'small' | 'big';
}
export const Banner = ({
	bannerStyle = {},
	isHtml = false,
	style = {},
	openInNewWindow = false,
	showIcons = false,
	text,
	title,
	buttonText,
	buttonLink = '/widgets',
	size,
}: IBannerProps) => {
	return (
		<Section style={style}>
			<StyledDiv style={bannerStyle} className="banner-container">
				{title && <h3>{title}</h3>}
				{!isHtml ? (
					<p className={size}>{text}</p>
				) : (
					<div
						className="rich-text"
						dangerouslySetInnerHTML={{ __html: text }}
					></div>
				)}
				{buttonText && (
					<CustomLink
						className={size}
						target={openInNewWindow ? '_blank' : '_self'}
						href={buttonLink}
					>
						{buttonText}
					</CustomLink>
				)}
				{showIcons && (
					<>
						<Image
							className="icons-left"
							src={'/assets/banner-icons-left.svg'}
							alt="floating icons"
							width={518}
							height={477}
						/>
						<Image
							className="icons-right"
							src={'/assets/banner-icons-right.svg'}
							alt="floating icons"
							width={466}
							height={443}
						/>
					</>
				)}
			</StyledDiv>
		</Section>
	);
};
