import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.gray900};
	text-align: center;

	h2 {
		font-weight: 600;
		font-size: ${CSSVars.fontSize4XL};
		margin-bottom: 50px;
		font-size: ${CSSVars.fontSize2XL};

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	.cards-container {
		max-width: 1420px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 50px;

		.card {
			flex: 300px;
			border-radius: 10px;
			background-color: ${CSSVars.gray100};
			padding: 20px;
			display: flex;
			align-items: center;
			gap: 10px;
			flex-direction: column;
			border: 1px solid ${CSSVars.gray200};

			h3 {
				font-weight: 600;
				color: ${CSSVars.primary500};
				font-size: ${CSSVars.fontSize3XL};

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSize4XL};
				}
			}

			h4 {
				font-weight: 600;
				font-size: ${CSSVars.fontSizeLG};

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeXL};
				}
			}

			p {
				font-weight: 300;
				max-width: 260px;
				font-size: ${CSSVars.fontSizeMD};

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeLG};
				}
			}
		}
	}
`;
